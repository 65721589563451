import React, { useState, useEffect, Fragment } from 'react';
import {
  FormControl,
  Box,
  ListItemButton,
  ListItemText,
  List,
  ListItem,
  ListItemIcon,
  ListItemAvatar,
  Avatar,
  TextField,
  Button,
  Typography,
  Alert,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import { red, indigo, green, grey, blue } from '@mui/material/colors';

import { API } from 'aws-amplify';
import Loading from 'util/loading';
import { borderRadius } from '@mui/system';

const ExternalUsers = ({ partnerId }) => {
  const [users, setUsers] = useState([]); // lista de usuários externos
  const [usernameToDelete, setUsernameToDelete] = useState(null); // usuário a apagar se clicar no confirmar
  const [loading, setLoading] = useState(false);
  const [newUserDialogOpen, setNewUserDialogOpen] = useState(false); // diálogo para inserir novo email de usuário

  // indicador de erro
  const [errorAlert, setErrorAlert] = useState(null);

  const styles = {
    addButton: {
      marginBottom: '16px',
    },
    user: {
      '&:hover': {
        backgroundColor: '#eeeeff',
      },
    },
    list: { margin: 2 },
    deleteButton: {
      color: 'white',
      backgroundColor: red[500],
      marginLeft: 'auto',
      height: 'fit-content',
      borderRadius: '50%',
    },
  };

  const listExternalUsers = async function () {
    try {
      setLoading(true);
      const apiName = 'lambdasApi';
      const path = '/listUser';
      const options = {
        body: {
          operatorId: partnerId,
        },
        headers: {},
      };
      const response = await API.post(apiName, path, options);
      setUsers(response);
    } catch (error) {
      console.log(error);
      setErrorAlert(error.toString());
    } finally {
      setLoading(false);
    }
  };

  const deleteUser = async () => {
    try {
      setLoading(true);
      const apiName = 'lambdasApi';
      const path = '/deleteUser';
      const options = {
        body: {
          username: usernameToDelete,
          operatorId: partnerId,
        },
        headers: {},
      };
      const response = await API.post(apiName, path, options);

      // remove da lista local
      const newUserList = users.filter(function (user) {
        return user !== usernameToDelete;
      });
      setUsers(newUserList);
    } catch (error) {
      console.log(error);
      setErrorAlert(error.toString());
    } finally {
      setLoading(false);
      setUsernameToDelete(null);
    }
  };

  useEffect(() => {
    listExternalUsers();
  }, [partnerId]);

  const handleDeleteUser = function (aUsername) {
    setUsernameToDelete(aUsername);
  };

  const addUser = async function (aEmail) {
    try {
      setLoading(true);
      const apiName = 'lambdasApi';
      const path = '/createUser';
      const options = {
        body: {
          email: aEmail,
          operatorId: partnerId,
        },
        headers: {},
      };
      const response = await API.post(apiName, path, options);
      setUsers((users) => [...users, aEmail]);
    } catch (error) {
      console.log(error);
      setErrorAlert(error.toString());
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Loading loading={loading} />

      {/* Confirmação de delete user */}
      <Dialog
        open={Boolean(usernameToDelete)}
        onClose={() => setUsernameToDelete(null)}
      >
        <DialogTitle id="alert-dialog-title">{'Remover usuário'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Quer realmente remover o usuário?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUsernameToDelete(null)} autoFocus>
            Cancelar
          </Button>
          <Button onClick={deleteUser}>Sim</Button>
        </DialogActions>
      </Dialog>

      {/* Mensagens de erro */}
      <Snackbar
        open={Boolean(errorAlert)}
        autoHideDuration={1500}
        onClose={() => setErrorAlert(null)}
      >
        <Alert
          onClose={() => setErrorAlert(null)}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {errorAlert}
        </Alert>
      </Snackbar>

      {/* Criar novo usuário externo */}
      <Dialog
        open={Boolean(newUserDialogOpen)}
        onClose={() => setNewUserDialogOpen(false)}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            console.log(email);
            addUser(email);
            setNewUserDialogOpen(false);
          },
        }}
      >
        <DialogTitle>Adicionar usuário externo</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Usuário para acessar a base de relatórios do parceiro.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Endereço de Email válido"
            type="email"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNewUserDialogOpen(false)}>Cancelar</Button>
          <Button type="submit">Adicionar</Button>
        </DialogActions>
      </Dialog>

      <Button
        variant="contained"
        type="submit"
        color="primary"
        onClick={() => setNewUserDialogOpen(true)}
        sx={styles.addButton}
      >
        <span>Adicionar usuário</span>
      </Button>

      {/* Lista dos usuários existentes */}
      <List sx={styles.list}>
        {users.map((username, index) => {
          return (
            <ListItem key={index} sx={styles.user}>
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon
                    sx={{
                      color: blue[500],
                    }}
                  />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={username} />
              <ListItemIcon onClick={() => handleDeleteUser(username)}>
                <ListItemButton sx={styles.deleteButton}>
                  <Delete />
                </ListItemButton>
              </ListItemIcon>
            </ListItem>
          );
        })}
      </List>
    </Fragment>
  );
};

export default ExternalUsers;
