import React, { useContext, useState, useEffect, Fragment } from 'react';
import OperatorContext from './context';
import {
  FormControl,
  Box,
  ListItemButton,
  ListItemText,
  List,
  ListItem,
  ListItemIcon,
  ListItemAvatar,
  Avatar,
  TextField,
  Button,
  Typography,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import Alert from 'components/Neo/Alert';
import { red, indigo, green, grey, blue } from '@mui/material/colors';

import * as actions from './actions';
import { API } from 'aws-amplify';
import { NotificationManager } from 'react-notifications';
import Loading from 'util/loading';

const ExternalUsers = ({ operatorId }) => {
  const { state, dispatch } = useContext(OperatorContext);
  const [users, setUsers] = useState([]);
  const [showAlertDeleteUser, setShowAlertDeleteUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [newUser, setNewUser] = useState('');
  const [usersChanged, setUsersChanged] = useState(0);

  async function listExternalUsers() {
    try {
      setLoading(true);
      const apiName = 'lambdasApi';
      const path = '/listUser';
      const options = {
        body: {
          operatorId: operatorId,
        },
        headers: {},
      };
      const response = await API.post(apiName, path, options);
      setUsers(response);
    } catch (error) {
      console.log(error);
      NotificationManager.error(error.toString(), null, 3000);
    } finally {
      setLoading(false);
    }
  }

  const deleteUser = async (aUsername) => {
    try {
      setLoading(true);
      const apiName = 'lambdasApi';
      const path = '/deleteUser';
      const options = {
        body: {
          username: aUsername,
          operatorId: operatorId,
        },
        headers: {},
      };
      console.log(options);
      const response = await API.post(apiName, path, options);
      console.log('RESPOSTA', response);

      // incrementa o contador para atualizar a lista de usuários
      setUsersChanged(usersChanged + 1);
    } catch (error) {
      console.log(error);
      NotificationManager.error(error.toString(), null, 3000);
    } finally {
      setLoading(false);
      setShowAlertDeleteUser(null);
    }
  };

  useEffect(() => {
    listExternalUsers();
  }, [usersChanged]);

  const handleDeleteUser = function (aUsername) {
    setShowAlertDeleteUser(aUsername);
  };

  async function handleUserSubmit() {
    try {
      setLoading(true);
      const apiName = 'lambdasApi';
      const path = '/createUser';
      const options = {
        body: {
          email: newUser,
          operatorId: operatorId,
        },
        headers: {},
      };
      const response = await API.post(apiName, path, options);
      // incrementa o contador para atualizar a lista de usuários
      setUsersChanged(usersChanged + 1);

      setNewUser('');
    } catch (error) {
      console.log(error);
      NotificationManager.error(error.toString(), null, 3000);
    } finally {
      setLoading(false);
    }
  }

  function handleChangeUser(event) {
    setNewUser(event.target.value);
  }

  return (
    <Fragment>
      <Loading loading={loading} />
      <Alert
        show={Boolean(showAlertDeleteUser)}
        onCancel={() => {
          setShowAlertDeleteUser(null);
        }}
        onOk={() => {
          deleteUser(showAlertDeleteUser);
        }}
        body="Isso irá remover esse usuário dessa operadora."
        title="Remover usuário"
      />
      {/* Campo para inserção de novo usuário */}
      <FormControl
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <TextField
          label="usuario"
          type="email"
          value={newUser}
          variant="standard"
          onChange={(event) => handleChangeUser(event)}
          required
        />
        <Button
          variant="contained"
          onClick={(event) => handleUserSubmit(event)}
          color="primary"
        >
          Cadastrar novo usuário
        </Button>
      </FormControl>
      {/* Lista dos usuários existentes */}
      <List sx={{ margin: 2 }}>
        {users.map((username, index) => {
          return (
            <ListItem key={index}>
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={username} />
              <ListItemIcon onClick={() => handleDeleteUser(username)}>
                <ListItemButton style={{ borderRadius: '50%' }}>
                  <Delete
                    sx={{
                      color: red[500],
                    }}
                  />
                </ListItemButton>
              </ListItemIcon>
            </ListItem>
          );
        })}
      </List>
    </Fragment>
  );
};

export default ExternalUsers;
