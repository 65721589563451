/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOperator = /* GraphQL */ `
  query GetOperator($id: ID!) {
    getOperator(id: $id) {
      id
      company_name
      trade
      corporate_registry
      disabled
      disabledDate
      headend
      evolution
      gatewayId
      econoGroup
      externalDisabled
      createdAt
      updatedAt
      regions {
        nextToken
        __typename
      }
      growthPlans {
        nextToken
        __typename
      }
      vods {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listOperators = /* GraphQL */ `
  query ListOperators(
    $filter: ModelOperatorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOperators(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRegion = /* GraphQL */ `
  query GetRegion($id: ID!) {
    getRegion(id: $id) {
      id
      operatorId
      technology
      disabled
      disabledDate
      mandatory
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      municipality {
        id
        code
        name
        state
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listRegions = /* GraphQL */ `
  query ListRegions(
    $filter: ModelRegionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRegions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        operatorId
        technology
        disabled
        disabledDate
        mandatory
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listRegionsByOperator = /* GraphQL */ `
  query ListRegionsByOperator(
    $operatorId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelRegionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRegionsByOperator(
      operatorId: $operatorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        operatorId
        technology
        disabled
        disabledDate
        mandatory
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVOD = /* GraphQL */ `
  query GetVOD($id: ID!) {
    getVOD(id: $id) {
      id
      name
      broadcasterId
      disabled
      disabledDate
      createdAt
      updatedAt
      operators {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listVODs = /* GraphQL */ `
  query ListVODs(
    $filter: ModelVODFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVODs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        broadcasterId
        disabled
        disabledDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const vodByBroadcaster = /* GraphQL */ `
  query VodByBroadcaster(
    $broadcasterId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelVODFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vodByBroadcaster(
      broadcasterId: $broadcasterId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        broadcasterId
        disabled
        disabledDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSVA = /* GraphQL */ `
  query GetSVA($id: ID!) {
    getSVA(id: $id) {
      id
      name
      broadcasterId
      incTable {
        userbase
        value
        discount
        __typename
      }
      isIncTable
      disabled
      disabledDate
      urn
      createdAt
      updatedAt
      growthPlans {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listSVAs = /* GraphQL */ `
  query ListSVAs(
    $filter: ModelSVAFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSVAs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        broadcasterId
        isIncTable
        disabled
        disabledDate
        urn
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const svaByBroadcaster = /* GraphQL */ `
  query SvaByBroadcaster(
    $broadcasterId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSVAFilterInput
    $limit: Int
    $nextToken: String
  ) {
    svaByBroadcaster(
      broadcasterId: $broadcasterId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        broadcasterId
        isIncTable
        disabled
        disabledDate
        urn
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGrowthPlan = /* GraphQL */ `
  query GetGrowthPlan($id: ID!) {
    getGrowthPlan(id: $id) {
      id
      svaId
      operatorId
      combo
      disabled
      disabledDate
      planTable {
        userbase
        month
        year
        __typename
      }
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      sva {
        id
        name
        broadcasterId
        isIncTable
        disabled
        disabledDate
        urn
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listGrowthPlans = /* GraphQL */ `
  query ListGrowthPlans(
    $filter: ModelGrowthPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGrowthPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        svaId
        operatorId
        combo
        disabled
        disabledDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const growthPlanByOperatorSva = /* GraphQL */ `
  query GrowthPlanByOperatorSva(
    $operatorId: ID
    $svaId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGrowthPlanFilterInput
    $limit: Int
    $nextToken: String
  ) {
    growthPlanByOperatorSva(
      operatorId: $operatorId
      svaId: $svaId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        svaId
        operatorId
        combo
        disabled
        disabledDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMunicipality = /* GraphQL */ `
  query GetMunicipality($id: ID!) {
    getMunicipality(id: $id) {
      id
      code
      name
      state
      createdAt
      updatedAt
      regions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listMunicipalitys = /* GraphQL */ `
  query ListMunicipalitys(
    $filter: ModelMunicipalityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMunicipalitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        name
        state
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const municipalityByState = /* GraphQL */ `
  query MunicipalityByState(
    $state: State
    $sortDirection: ModelSortDirection
    $filter: ModelMunicipalityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    municipalityByState(
      state: $state
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        name
        state
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBroadcaster = /* GraphQL */ `
  query GetBroadcaster($id: ID!) {
    getBroadcaster(id: $id) {
      id
      company_name
      trade
      corporate_registry
      flexibleTables
      disabled
      disabledDate
      layout
      layoutSva
      layoutVod
      increasePercentage
      createdAt
      updatedAt
      vod {
        nextToken
        __typename
      }
      svas {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      channels {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listBroadcasters = /* GraphQL */ `
  query ListBroadcasters(
    $filter: ModelBroadcasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBroadcasters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        company_name
        trade
        corporate_registry
        flexibleTables
        disabled
        disabledDate
        layout
        layoutSva
        layoutVod
        increasePercentage
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLineup = /* GraphQL */ `
  query GetLineup($id: ID!) {
    getLineup(id: $id) {
      id
      channelId
      contractId
      disabled
      disabledDate
      penetration
      createdAt
      updatedAt
      contract {
        id
        broadcasterId
        operatorId
        contract_number
        customer_code
        distribution
        packing
        startDate
        billingCode
        createdAt
        updatedAt
        __typename
      }
      channel {
        id
        name
        broadcasterId
        disabled
        disabledDate
        paytv
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listLineups = /* GraphQL */ `
  query ListLineups(
    $filter: ModelLineupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLineups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        channelId
        contractId
        disabled
        disabledDate
        penetration
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listLineupByChannel = /* GraphQL */ `
  query ListLineupByChannel(
    $channelId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLineupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLineupByChannel(
      channelId: $channelId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        channelId
        contractId
        disabled
        disabledDate
        penetration
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listLineupByContract = /* GraphQL */ `
  query ListLineupByContract(
    $contractId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelLineupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLineupByContract(
      contractId: $contractId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        channelId
        contractId
        disabled
        disabledDate
        penetration
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getVod2Operator = /* GraphQL */ `
  query GetVod2Operator($id: ID!) {
    getVod2Operator(id: $id) {
      id
      vodId
      operatorId
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      vod {
        id
        name
        broadcasterId
        disabled
        disabledDate
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listVod2Operators = /* GraphQL */ `
  query ListVod2Operators(
    $filter: ModelVod2OperatorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVod2Operators(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        vodId
        operatorId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listV2OByVod = /* GraphQL */ `
  query ListV2OByVod(
    $vodId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelVod2OperatorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listV2OByVod(
      vodId: $vodId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vodId
        operatorId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listV2OByOperator = /* GraphQL */ `
  query ListV2OByOperator(
    $operatorId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelVod2OperatorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listV2OByOperator(
      operatorId: $operatorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        vodId
        operatorId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getContract = /* GraphQL */ `
  query GetContract($id: ID!) {
    getContract(id: $id) {
      id
      broadcasterId
      operatorId
      contract_number
      customer_code
      distribution
      packing
      startDate
      billingCode
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      broadcaster {
        id
        company_name
        trade
        corporate_registry
        flexibleTables
        disabled
        disabledDate
        layout
        layoutSva
        layoutVod
        increasePercentage
        createdAt
        updatedAt
        __typename
      }
      lineup {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listContracts = /* GraphQL */ `
  query ListContracts(
    $filter: ModelContractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContracts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        broadcasterId
        operatorId
        contract_number
        customer_code
        distribution
        packing
        startDate
        billingCode
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listContractsByBroadcaster = /* GraphQL */ `
  query ListContractsByBroadcaster(
    $broadcasterId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelContractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContractsByBroadcaster(
      broadcasterId: $broadcasterId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        broadcasterId
        operatorId
        contract_number
        customer_code
        distribution
        packing
        startDate
        billingCode
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listContractsByOperator = /* GraphQL */ `
  query ListContractsByOperator(
    $operatorId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelContractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContractsByOperator(
      operatorId: $operatorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        broadcasterId
        operatorId
        contract_number
        customer_code
        distribution
        packing
        startDate
        billingCode
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getChannel = /* GraphQL */ `
  query GetChannel($id: ID!) {
    getChannel(id: $id) {
      id
      name
      broadcasterId
      disabled
      disabledDate
      paytv
      createdAt
      updatedAt
      broadcaster {
        id
        company_name
        trade
        corporate_registry
        flexibleTables
        disabled
        disabledDate
        layout
        layoutSva
        layoutVod
        increasePercentage
        createdAt
        updatedAt
        __typename
      }
      lineups {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listChannels = /* GraphQL */ `
  query ListChannels(
    $filter: ModelChannelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChannels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        broadcasterId
        disabled
        disabledDate
        paytv
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listChannelsByBroadcaster = /* GraphQL */ `
  query ListChannelsByBroadcaster(
    $broadcasterId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelChannelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChannelsByBroadcaster(
      broadcasterId: $broadcasterId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        broadcasterId
        disabled
        disabledDate
        paytv
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGeoJson = /* GraphQL */ `
  query GetGeoJson($id: ID!) {
    getGeoJson(id: $id) {
      id
      code
      data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGeoJsons = /* GraphQL */ `
  query ListGeoJsons(
    $filter: ModelGeoJsonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGeoJsons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        data
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const geoJsonByCode = /* GraphQL */ `
  query GeoJsonByCode(
    $code: String
    $sortDirection: ModelSortDirection
    $filter: ModelGeoJsonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    geoJsonByCode(
      code: $code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        code
        data
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getReportWarning = /* GraphQL */ `
  query GetReportWarning($id: ID!) {
    getReportWarning(id: $id) {
      id
      period
      operatorId
      broadcasterId
      regionId
      warning
      sheet
      comments {
        author
        contents
        __typename
      }
      warningType
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      region {
        id
        operatorId
        technology
        disabled
        disabledDate
        mandatory
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const listReportWarnings = /* GraphQL */ `
  query ListReportWarnings(
    $filter: ModelReportWarningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportWarnings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        period
        operatorId
        broadcasterId
        regionId
        warning
        sheet
        warningType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const warningByOperator = /* GraphQL */ `
  query WarningByOperator(
    $operatorId: ID
    $period: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportWarningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    warningByOperator(
      operatorId: $operatorId
      period: $period
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        period
        operatorId
        broadcasterId
        regionId
        warning
        sheet
        warningType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const warningByBroadcaster = /* GraphQL */ `
  query WarningByBroadcaster(
    $broadcasterId: ID
    $period: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportWarningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    warningByBroadcaster(
      broadcasterId: $broadcasterId
      period: $period
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        period
        operatorId
        broadcasterId
        regionId
        warning
        sheet
        warningType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const warningByPeriod = /* GraphQL */ `
  query WarningByPeriod(
    $period: String
    $sortDirection: ModelSortDirection
    $filter: ModelReportWarningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    warningByPeriod(
      period: $period
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        period
        operatorId
        broadcasterId
        regionId
        warning
        sheet
        warningType
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
