import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  Fragment,
} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  FormControl,
  Switch,
  Box,
  Tooltip,
  Snackbar,
  Card,
  CardContent,
  CardHeader,
  Alert,
} from '@mui/material';

import { Backup, Add, Delete, ExpandMore } from '@mui/icons-material';

import * as mutations from 'graphql/mutations';
import * as queries from 'graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { v4 } from 'uuid';

import * as customQueries from 'graphql/custom/queries';
import * as customGql from 'graphql/custom/functions';

import Loading from 'util/loading';

const styles = {
  vod: {
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
    marginBottom: 1,
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: '#eeeeee',
    },
  },
  partnerBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
};

function plain(str) {
  return str
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}

function comparePartners(a, b) {
  if (plain(a.trade) > plain(b.trade)) {
    return 1;
  }

  if (plain(a.trade) < plain(b.trade)) {
    return -1;
  }

  return 0;
}

function filterDisabled(aItem) {
  return !Boolean(aItem.disabled);
}

function filterPartnerWithVod(aPartner) {
  return aPartner.vod && aPartner.vod.items && aPartner.vod.items.length > 0;
}

const OperatorVods = ({ operatorId }) => {
  const [partners, setPartners] = useState([]);
  const [operatorVods, setOperatorVods] = useState([]);
  const [errorAlert, setErrorAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);

  /**
   *
   * Puxa a listagem de todos os parceiros com seus vods
   */
  const listPartners = async function () {
    try {
      setLoading(true);
      const allPartners = await customGql.listAll(
        customQueries.listBroadcasters
      );
      const p = allPartners.filter(filterPartnerWithVod).sort(comparePartners);
      setPartners(p);
    } catch (error) {
      setErrorAlert(error.toString());
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    listPartners();
  }, []);

  useEffect(() => {
    if (operatorId.length > 0) {
      updateOperatorVods();
    }
  }, [operatorId]);

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const hasVod = function (aVodId) {
    for (const vod of operatorVods) {
      if (vod.vodId === aVodId) return true;
    }
    return false;
  };

  const updateOperatorVods = async function () {
    try {
      setLoading(true);

      const result = await API.graphql(
        graphqlOperation(queries.listV2OByOperator, {
          operatorId: operatorId,
          limit: 1000,
        })
      );

      if (result.errors) {
        console.log(result.errors);
        throw new Error(result.errors[0].message);
      }

      setOperatorVods(result.data.listV2OByOperator.items);
    } catch (error) {
      setErrorAlert(error.toString());
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Acrescenta o VOD ao operador (na tabela Vod2Operator)
   */
  const onAddVod = async function (aVodId) {
    try {
      setLoading(true);

      const myInput = {
        id: v4(),
        vodId: aVodId,
        vod2OperatorVodId: aVodId,
        vod2OperatorOperatorId: operatorId,
        operatorId: operatorId,
      };

      const operation = mutations.createVod2Operator;

      const result = await API.graphql(
        graphqlOperation(operation, { input: myInput })
      );

      if (result.errors) {
        console.log(result.errors);
        throw new Error(result.errors[0].message);
      }

      updateOperatorVods();
    } catch (error) {
      setErrorAlert(error.toString());
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  function handleToggle(aEvent, aVodId) {
    const vodEnabled = aEvent.target.checked;

    if (vodEnabled) {
      onAddVod(aVodId);
    } else {
      onDeleteVod(aVodId);
    }
  }

  const onDeleteVod = async function (aVodId) {
    try {
      setLoading(true);

      // pega o ID do objeto Vod2Operator (tabela)
      // pelo ID do vod
      let id = null;
      for (const vod2O of operatorVods) {
        if (vod2O.vodId === aVodId) {
          id = vod2O.id;
        }
      }

      if (id) {
        const myInput = {
          id: id,
        };

        const operation = mutations.deleteVod2Operator;

        const result = await API.graphql(
          graphqlOperation(operation, { input: myInput })
        );

        if (result.errors) {
          console.log(result.errors);
          throw new Error(result.errors[0].message);
        }

        updateOperatorVods();
      }
    } catch (error) {
      setErrorAlert(error.toString());
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Loading loading={loading} />

      {/* Caixa com a listagem dos VODs por parceiro*/}
      <Box sx={styles.partnerBox}>
        {partners.map((partner, idx) => (
          <Accordion
            expanded={expanded === partner.id}
            onChange={handleChangeAccordion(partner.id)}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              {partner.trade}
            </AccordionSummary>

            <AccordionDetails>
              {partner.vod.items.filter(filterDisabled).map((vod, idx) => (
                <FormControl key={vod.id} sx={styles.vod}>
                  <Box>{vod.name}</Box>

                  <FormControl>
                    <Tooltip title="Habilitar/Desabilitar">
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            onClick={(event) => handleToggle(event, vod.id)}
                            checked={hasVod(vod.id)}
                          />
                        }
                        label="Habilitado"
                      />
                    </Tooltip>
                  </FormControl>
                </FormControl>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>

      {/* Mensagens de erro  */}
      <Snackbar
        open={Boolean(errorAlert)}
        autoHideDuration={1500}
        onClose={() => setErrorAlert(null)}
      >
        <Alert
          onClose={() => setErrorAlert(null)}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {errorAlert}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default OperatorVods;
