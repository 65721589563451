/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createOperator = /* GraphQL */ `
  mutation CreateOperator(
    $input: CreateOperatorInput!
    $condition: ModelOperatorConditionInput
  ) {
    createOperator(input: $input, condition: $condition) {
      id
      company_name
      trade
      corporate_registry
      disabled
      disabledDate
      headend
      evolution
      gatewayId
      econoGroup
      externalDisabled
      createdAt
      updatedAt
      regions {
        nextToken
        __typename
      }
      growthPlans {
        nextToken
        __typename
      }
      vods {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateOperator = /* GraphQL */ `
  mutation UpdateOperator(
    $input: UpdateOperatorInput!
    $condition: ModelOperatorConditionInput
  ) {
    updateOperator(input: $input, condition: $condition) {
      id
      company_name
      trade
      corporate_registry
      disabled
      disabledDate
      headend
      evolution
      gatewayId
      econoGroup
      externalDisabled
      createdAt
      updatedAt
      regions {
        nextToken
        __typename
      }
      growthPlans {
        nextToken
        __typename
      }
      vods {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteOperator = /* GraphQL */ `
  mutation DeleteOperator(
    $input: DeleteOperatorInput!
    $condition: ModelOperatorConditionInput
  ) {
    deleteOperator(input: $input, condition: $condition) {
      id
      company_name
      trade
      corporate_registry
      disabled
      disabledDate
      headend
      evolution
      gatewayId
      econoGroup
      externalDisabled
      createdAt
      updatedAt
      regions {
        nextToken
        __typename
      }
      growthPlans {
        nextToken
        __typename
      }
      vods {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createRegion = /* GraphQL */ `
  mutation CreateRegion(
    $input: CreateRegionInput!
    $condition: ModelRegionConditionInput
  ) {
    createRegion(input: $input, condition: $condition) {
      id
      operatorId
      technology
      disabled
      disabledDate
      mandatory
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      municipality {
        id
        code
        name
        state
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateRegion = /* GraphQL */ `
  mutation UpdateRegion(
    $input: UpdateRegionInput!
    $condition: ModelRegionConditionInput
  ) {
    updateRegion(input: $input, condition: $condition) {
      id
      operatorId
      technology
      disabled
      disabledDate
      mandatory
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      municipality {
        id
        code
        name
        state
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteRegion = /* GraphQL */ `
  mutation DeleteRegion(
    $input: DeleteRegionInput!
    $condition: ModelRegionConditionInput
  ) {
    deleteRegion(input: $input, condition: $condition) {
      id
      operatorId
      technology
      disabled
      disabledDate
      mandatory
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      municipality {
        id
        code
        name
        state
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createVOD = /* GraphQL */ `
  mutation CreateVOD(
    $input: CreateVODInput!
    $condition: ModelVODConditionInput
  ) {
    createVOD(input: $input, condition: $condition) {
      id
      name
      broadcasterId
      disabled
      disabledDate
      createdAt
      updatedAt
      operators {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateVOD = /* GraphQL */ `
  mutation UpdateVOD(
    $input: UpdateVODInput!
    $condition: ModelVODConditionInput
  ) {
    updateVOD(input: $input, condition: $condition) {
      id
      name
      broadcasterId
      disabled
      disabledDate
      createdAt
      updatedAt
      operators {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteVOD = /* GraphQL */ `
  mutation DeleteVOD(
    $input: DeleteVODInput!
    $condition: ModelVODConditionInput
  ) {
    deleteVOD(input: $input, condition: $condition) {
      id
      name
      broadcasterId
      disabled
      disabledDate
      createdAt
      updatedAt
      operators {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createSVA = /* GraphQL */ `
  mutation CreateSVA(
    $input: CreateSVAInput!
    $condition: ModelSVAConditionInput
  ) {
    createSVA(input: $input, condition: $condition) {
      id
      name
      broadcasterId
      incTable {
        userbase
        value
        discount
        __typename
      }
      isIncTable
      disabled
      disabledDate
      urn
      createdAt
      updatedAt
      growthPlans {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateSVA = /* GraphQL */ `
  mutation UpdateSVA(
    $input: UpdateSVAInput!
    $condition: ModelSVAConditionInput
  ) {
    updateSVA(input: $input, condition: $condition) {
      id
      name
      broadcasterId
      incTable {
        userbase
        value
        discount
        __typename
      }
      isIncTable
      disabled
      disabledDate
      urn
      createdAt
      updatedAt
      growthPlans {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteSVA = /* GraphQL */ `
  mutation DeleteSVA(
    $input: DeleteSVAInput!
    $condition: ModelSVAConditionInput
  ) {
    deleteSVA(input: $input, condition: $condition) {
      id
      name
      broadcasterId
      incTable {
        userbase
        value
        discount
        __typename
      }
      isIncTable
      disabled
      disabledDate
      urn
      createdAt
      updatedAt
      growthPlans {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createGrowthPlan = /* GraphQL */ `
  mutation CreateGrowthPlan(
    $input: CreateGrowthPlanInput!
    $condition: ModelGrowthPlanConditionInput
  ) {
    createGrowthPlan(input: $input, condition: $condition) {
      id
      svaId
      operatorId
      combo
      disabled
      disabledDate
      planTable {
        userbase
        month
        year
        __typename
      }
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      sva {
        id
        name
        broadcasterId
        isIncTable
        disabled
        disabledDate
        urn
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateGrowthPlan = /* GraphQL */ `
  mutation UpdateGrowthPlan(
    $input: UpdateGrowthPlanInput!
    $condition: ModelGrowthPlanConditionInput
  ) {
    updateGrowthPlan(input: $input, condition: $condition) {
      id
      svaId
      operatorId
      combo
      disabled
      disabledDate
      planTable {
        userbase
        month
        year
        __typename
      }
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      sva {
        id
        name
        broadcasterId
        isIncTable
        disabled
        disabledDate
        urn
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteGrowthPlan = /* GraphQL */ `
  mutation DeleteGrowthPlan(
    $input: DeleteGrowthPlanInput!
    $condition: ModelGrowthPlanConditionInput
  ) {
    deleteGrowthPlan(input: $input, condition: $condition) {
      id
      svaId
      operatorId
      combo
      disabled
      disabledDate
      planTable {
        userbase
        month
        year
        __typename
      }
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      sva {
        id
        name
        broadcasterId
        isIncTable
        disabled
        disabledDate
        urn
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createMunicipality = /* GraphQL */ `
  mutation CreateMunicipality(
    $input: CreateMunicipalityInput!
    $condition: ModelMunicipalityConditionInput
  ) {
    createMunicipality(input: $input, condition: $condition) {
      id
      code
      name
      state
      createdAt
      updatedAt
      regions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateMunicipality = /* GraphQL */ `
  mutation UpdateMunicipality(
    $input: UpdateMunicipalityInput!
    $condition: ModelMunicipalityConditionInput
  ) {
    updateMunicipality(input: $input, condition: $condition) {
      id
      code
      name
      state
      createdAt
      updatedAt
      regions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteMunicipality = /* GraphQL */ `
  mutation DeleteMunicipality(
    $input: DeleteMunicipalityInput!
    $condition: ModelMunicipalityConditionInput
  ) {
    deleteMunicipality(input: $input, condition: $condition) {
      id
      code
      name
      state
      createdAt
      updatedAt
      regions {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createBroadcaster = /* GraphQL */ `
  mutation CreateBroadcaster(
    $input: CreateBroadcasterInput!
    $condition: ModelBroadcasterConditionInput
  ) {
    createBroadcaster(input: $input, condition: $condition) {
      id
      company_name
      trade
      corporate_registry
      flexibleTables
      disabled
      disabledDate
      layout
      layoutSva
      layoutVod
      increasePercentage
      createdAt
      updatedAt
      vod {
        nextToken
        __typename
      }
      svas {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      channels {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateBroadcaster = /* GraphQL */ `
  mutation UpdateBroadcaster(
    $input: UpdateBroadcasterInput!
    $condition: ModelBroadcasterConditionInput
  ) {
    updateBroadcaster(input: $input, condition: $condition) {
      id
      company_name
      trade
      corporate_registry
      flexibleTables
      disabled
      disabledDate
      layout
      layoutSva
      layoutVod
      increasePercentage
      createdAt
      updatedAt
      vod {
        nextToken
        __typename
      }
      svas {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      channels {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteBroadcaster = /* GraphQL */ `
  mutation DeleteBroadcaster(
    $input: DeleteBroadcasterInput!
    $condition: ModelBroadcasterConditionInput
  ) {
    deleteBroadcaster(input: $input, condition: $condition) {
      id
      company_name
      trade
      corporate_registry
      flexibleTables
      disabled
      disabledDate
      layout
      layoutSva
      layoutVod
      increasePercentage
      createdAt
      updatedAt
      vod {
        nextToken
        __typename
      }
      svas {
        nextToken
        __typename
      }
      contracts {
        nextToken
        __typename
      }
      channels {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createLineup = /* GraphQL */ `
  mutation CreateLineup(
    $input: CreateLineupInput!
    $condition: ModelLineupConditionInput
  ) {
    createLineup(input: $input, condition: $condition) {
      id
      channelId
      contractId
      disabled
      disabledDate
      penetration
      createdAt
      updatedAt
      contract {
        id
        broadcasterId
        operatorId
        contract_number
        customer_code
        distribution
        packing
        startDate
        billingCode
        createdAt
        updatedAt
        __typename
      }
      channel {
        id
        name
        broadcasterId
        disabled
        disabledDate
        paytv
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateLineup = /* GraphQL */ `
  mutation UpdateLineup(
    $input: UpdateLineupInput!
    $condition: ModelLineupConditionInput
  ) {
    updateLineup(input: $input, condition: $condition) {
      id
      channelId
      contractId
      disabled
      disabledDate
      penetration
      createdAt
      updatedAt
      contract {
        id
        broadcasterId
        operatorId
        contract_number
        customer_code
        distribution
        packing
        startDate
        billingCode
        createdAt
        updatedAt
        __typename
      }
      channel {
        id
        name
        broadcasterId
        disabled
        disabledDate
        paytv
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteLineup = /* GraphQL */ `
  mutation DeleteLineup(
    $input: DeleteLineupInput!
    $condition: ModelLineupConditionInput
  ) {
    deleteLineup(input: $input, condition: $condition) {
      id
      channelId
      contractId
      disabled
      disabledDate
      penetration
      createdAt
      updatedAt
      contract {
        id
        broadcasterId
        operatorId
        contract_number
        customer_code
        distribution
        packing
        startDate
        billingCode
        createdAt
        updatedAt
        __typename
      }
      channel {
        id
        name
        broadcasterId
        disabled
        disabledDate
        paytv
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createVod2Operator = /* GraphQL */ `
  mutation CreateVod2Operator(
    $input: CreateVod2OperatorInput!
    $condition: ModelVod2OperatorConditionInput
  ) {
    createVod2Operator(input: $input, condition: $condition) {
      id
      vodId
      operatorId
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      vod {
        id
        name
        broadcasterId
        disabled
        disabledDate
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateVod2Operator = /* GraphQL */ `
  mutation UpdateVod2Operator(
    $input: UpdateVod2OperatorInput!
    $condition: ModelVod2OperatorConditionInput
  ) {
    updateVod2Operator(input: $input, condition: $condition) {
      id
      vodId
      operatorId
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      vod {
        id
        name
        broadcasterId
        disabled
        disabledDate
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteVod2Operator = /* GraphQL */ `
  mutation DeleteVod2Operator(
    $input: DeleteVod2OperatorInput!
    $condition: ModelVod2OperatorConditionInput
  ) {
    deleteVod2Operator(input: $input, condition: $condition) {
      id
      vodId
      operatorId
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      vod {
        id
        name
        broadcasterId
        disabled
        disabledDate
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const createContract = /* GraphQL */ `
  mutation CreateContract(
    $input: CreateContractInput!
    $condition: ModelContractConditionInput
  ) {
    createContract(input: $input, condition: $condition) {
      id
      broadcasterId
      operatorId
      contract_number
      customer_code
      distribution
      packing
      startDate
      billingCode
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      broadcaster {
        id
        company_name
        trade
        corporate_registry
        flexibleTables
        disabled
        disabledDate
        layout
        layoutSva
        layoutVod
        increasePercentage
        createdAt
        updatedAt
        __typename
      }
      lineup {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateContract = /* GraphQL */ `
  mutation UpdateContract(
    $input: UpdateContractInput!
    $condition: ModelContractConditionInput
  ) {
    updateContract(input: $input, condition: $condition) {
      id
      broadcasterId
      operatorId
      contract_number
      customer_code
      distribution
      packing
      startDate
      billingCode
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      broadcaster {
        id
        company_name
        trade
        corporate_registry
        flexibleTables
        disabled
        disabledDate
        layout
        layoutSva
        layoutVod
        increasePercentage
        createdAt
        updatedAt
        __typename
      }
      lineup {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteContract = /* GraphQL */ `
  mutation DeleteContract(
    $input: DeleteContractInput!
    $condition: ModelContractConditionInput
  ) {
    deleteContract(input: $input, condition: $condition) {
      id
      broadcasterId
      operatorId
      contract_number
      customer_code
      distribution
      packing
      startDate
      billingCode
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      broadcaster {
        id
        company_name
        trade
        corporate_registry
        flexibleTables
        disabled
        disabledDate
        layout
        layoutSva
        layoutVod
        increasePercentage
        createdAt
        updatedAt
        __typename
      }
      lineup {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createChannel = /* GraphQL */ `
  mutation CreateChannel(
    $input: CreateChannelInput!
    $condition: ModelChannelConditionInput
  ) {
    createChannel(input: $input, condition: $condition) {
      id
      name
      broadcasterId
      disabled
      disabledDate
      paytv
      createdAt
      updatedAt
      broadcaster {
        id
        company_name
        trade
        corporate_registry
        flexibleTables
        disabled
        disabledDate
        layout
        layoutSva
        layoutVod
        increasePercentage
        createdAt
        updatedAt
        __typename
      }
      lineups {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateChannel = /* GraphQL */ `
  mutation UpdateChannel(
    $input: UpdateChannelInput!
    $condition: ModelChannelConditionInput
  ) {
    updateChannel(input: $input, condition: $condition) {
      id
      name
      broadcasterId
      disabled
      disabledDate
      paytv
      createdAt
      updatedAt
      broadcaster {
        id
        company_name
        trade
        corporate_registry
        flexibleTables
        disabled
        disabledDate
        layout
        layoutSva
        layoutVod
        increasePercentage
        createdAt
        updatedAt
        __typename
      }
      lineups {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteChannel = /* GraphQL */ `
  mutation DeleteChannel(
    $input: DeleteChannelInput!
    $condition: ModelChannelConditionInput
  ) {
    deleteChannel(input: $input, condition: $condition) {
      id
      name
      broadcasterId
      disabled
      disabledDate
      paytv
      createdAt
      updatedAt
      broadcaster {
        id
        company_name
        trade
        corporate_registry
        flexibleTables
        disabled
        disabledDate
        layout
        layoutSva
        layoutVod
        increasePercentage
        createdAt
        updatedAt
        __typename
      }
      lineups {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      message
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createGeoJson = /* GraphQL */ `
  mutation CreateGeoJson(
    $input: CreateGeoJsonInput!
    $condition: ModelGeoJsonConditionInput
  ) {
    createGeoJson(input: $input, condition: $condition) {
      id
      code
      data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGeoJson = /* GraphQL */ `
  mutation UpdateGeoJson(
    $input: UpdateGeoJsonInput!
    $condition: ModelGeoJsonConditionInput
  ) {
    updateGeoJson(input: $input, condition: $condition) {
      id
      code
      data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGeoJson = /* GraphQL */ `
  mutation DeleteGeoJson(
    $input: DeleteGeoJsonInput!
    $condition: ModelGeoJsonConditionInput
  ) {
    deleteGeoJson(input: $input, condition: $condition) {
      id
      code
      data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createReportWarning = /* GraphQL */ `
  mutation CreateReportWarning(
    $input: CreateReportWarningInput!
    $condition: ModelReportWarningConditionInput
  ) {
    createReportWarning(input: $input, condition: $condition) {
      id
      period
      operatorId
      broadcasterId
      regionId
      warning
      sheet
      comments {
        author
        contents
        __typename
      }
      warningType
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      region {
        id
        operatorId
        technology
        disabled
        disabledDate
        mandatory
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const updateReportWarning = /* GraphQL */ `
  mutation UpdateReportWarning(
    $input: UpdateReportWarningInput!
    $condition: ModelReportWarningConditionInput
  ) {
    updateReportWarning(input: $input, condition: $condition) {
      id
      period
      operatorId
      broadcasterId
      regionId
      warning
      sheet
      comments {
        author
        contents
        __typename
      }
      warningType
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      region {
        id
        operatorId
        technology
        disabled
        disabledDate
        mandatory
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
export const deleteReportWarning = /* GraphQL */ `
  mutation DeleteReportWarning(
    $input: DeleteReportWarningInput!
    $condition: ModelReportWarningConditionInput
  ) {
    deleteReportWarning(input: $input, condition: $condition) {
      id
      period
      operatorId
      broadcasterId
      regionId
      warning
      sheet
      comments {
        author
        contents
        __typename
      }
      warningType
      createdAt
      updatedAt
      operator {
        id
        company_name
        trade
        corporate_registry
        disabled
        disabledDate
        headend
        evolution
        gatewayId
        econoGroup
        externalDisabled
        createdAt
        updatedAt
        __typename
      }
      region {
        id
        operatorId
        technology
        disabled
        disabledDate
        mandatory
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
  }
`;
